<template>
  <div class="">
    <b-row class="justify-content-center">
      <div class="row justify-center col-md-12">
        <div class="col-md-12">
          <div class="d-flex col-md-12 mb-3 mt-3">
            <div class="col-md-6 aling-right text-right">
              <h4 class="font-1rem margin-top-2px">{{ $t("buyer") }}</h4>
            </div>
            <div class="text-center">
              <b-avatar size="23" variant="light-secondary" class="">
                <feather-icon
                    icon="UserCircleIcon'"
                    size="22"
                    class="cursor-pointer"
                />
              </b-avatar>
            </div>
            <div class="col-md-5 text-left">
              <h4
                  class="font-1rem margin-top-2px"
                  v-if="
                  $store.state && $store.state.auth && $store.state.auth.user
                "
              >
                {{ $store.state.auth.user.name }}
              </h4>
            </div>
          </div>
          <div
              v-for="(item, index) in items"
              :key="index"
              :class="item.invest ? 'alert alert-success p-2' : ''"
          >
            <div
                class="d-flex col-md-12 mb-3 mt-3" v-if="item.visible==1">
              <div v-if="!item.slider" class="col-md-6 aling-right text-right">
                <h4
                    :style="item.invest ? 'font-weight:900' : ''"
                    class="font-1rem margin-top-2px"
                >
                  {{ $t(item.text) }}
                </h4>
              </div>
              <div class="text-center" v-if="!item.slider">
                <b-avatar size="23" variant="light-secondary" class="">
                  <feather-icon
                      icon="HelpCircleIcon"
                      size="22"
                      class="cursor-pointer"
                  />
                </b-avatar>
              </div>
              <div v-if="!item.progress && !item.slider"
                   class="col-md-5 text-left"
              >
                <h4 class="font-1rem margin-top-2px">{{ item.des }}</h4>
              </div>

              <div v-if="item.progress" class="col-md-2 text-left d-flex">

                <span class="font-1rem font-weight-bold">{{ funding_status }} </span>
                <span class="mr-1 font-1rem font-weight-bold">%</span>

                <b-progress
                    style="width: 100% !important"
                    :value="funding_status"
                    max="100"
                    variant="success"
                    height="20px"
                />
              </div>

              <div v-if="item.slider"
                   class="
                col-md-12
                row
                justify-content-center
                text-align-center text-center
                pb-1
              "
              >
                <div class="col-md-12" align="center">
                  <h3 class="font-1rem">{{ $t(item.text) }}</h3>
                </div>

                <div class="col-xl-10 col-lg-10 col-md-12 p-0 m-0">
                  <vue-slider
                    v-model.trim="slider"
                    height="15px"
                    id="slider-buttons"
                    @change="checkInvoice"
                    :marks="true"
                    :data="sliderData"
                    :tooltip="'none'"
                    :adsorb="true"
                    class="vue-slider-success"
                    :direction="'ltr'"
                    :lazy="true"
                    ref="slider"
                  >
                  </vue-slider>
                </div>
                <div class="col-md-12 mt-3">
                  <h3
                      class="text-success mt-2"
                      style="color: #b0d24b; font-weight: bolder"
                  >
                    <span v-if="calcPer(slider, sliderData[0]) == 100">100%</span>
                    <span
                        v-else-if="slider == '0%'"
                        @load="$emit('disableBuy', true)"
                    >{{ slider }}
                  </span>
                    <span v-else>{{ calcPer(slider, sliderData[0]) }}% </span>
                    {{ $t("der_investition") }}
                  </h3>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </b-row>

    <div class="col-md-12 justify-content-center p-0" align="center">
      <div class="pt-1 pb-1 pl-3 pr-2">
        <h4 class="row font-1rem">
          <label style="margin-top: 3px" class="font-1rem"
          >{{ $t("agree_with_purchase_of_the_invoice") }}

            <span class="font-1rem">
          <strong> <a @click="toTerms()" class="font-1rem">{{
              $t("agree_with_purchase_of_the_invoice_link")
            }}</a></strong>

            </span>
            <span class="font-1rem" v-if="$store.state.app.language == 'de'">
              {{ $t("agree_with_purchase_of_the_invoice_after_link") }}
            </span>
          </label>
        </h4>
      </div>
    </div>
    <hr/>
  </div>
</template>
<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {CurrencyAmountFormat} from '@core/utils/CurrencyAmountFormat';

import i18n from "@/libs/i18n";
import VueSlider from "vue-slider-component";

export default {
  components: {
    VueSlider,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      current_balance: 0,
      slider: "0%",
      sliderData: [
      //   '0%',
      //   '10%',
      //   '20%',
      //   '30%',
      //   '40%',
      //   '50%',
      //   '60%',
      //   '70%',
      //   '80%',
      //   '90%',
      //   '100%',
      ],
      displayWidth: window.innerWidth,
      details: [
        {
          text: i18n.t("buyer"),
          des: this.$store.state.auth.user.name,
          icon: "UserIcon",
        },
      ],
      items: [],
      funding_status: 0,
      fractions: [
        {
          fraction: 10,
        },
        {
          fraction: 5,
        },
        {
          fraction: 2,
        },
      ],
      loader: true,
    };
  },

  props: ["modal"],
  created() {
    this.checkForBalance();
    this.getInvoice();
    setTimeout(() => {
      console.log('test');
      this.select100();
    }, 1500);
    // this.calcPer(this.funding_status+'%');

    // if(this.invoice.partial_purchase) {
    //   this.sliderData = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']
    // } else {
    //   this.sliderData = ['0%', '100%']
    // }

  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },

    toTerms() {
      if (this.$i18n.locale == "en") {
        window.open("https://www.aequitex.com/terms-condition", "_blank");
      } else {
        window.open("https://www.aequitex.com/de/bedingungen-und-konditionen", "_blank");
      }
    },
    select100() {
      console.log('asdasd');
      for (const div of document.querySelectorAll('div')) {
        if (div.innerHTML == '100%') {
          div.click();
        }
      }
    },
    calcPer(slider) {
      let res = 100;

      if (slider) {
        let sl = slider.replace("%", "");

        if (sl != "100%") {
          if (Number(sl) - Number(this.funding_status) <= 0) {
            this.$emit("disableBuy", true);
          } else {
            this.$emit("disableBuy", false);
          }
          res = Number(sl) - Number(this.funding_status);
        }

        if (Number(sl) == this.funding_status) {
          this.$emit("disableBuy", true);
        }
      }

      return res;
    },
    onResize() {
      this.displayWidth = window.innerWidth;
    },
    checkForBalance() {
    },
    showSuccessMessage() {
      let text = this.$t("invoice_bought_successfully");
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((res) => {
        this.$emit("goNext");
      });
    },
    checkInvoice() {
      if (this.slider.replace("%", "") < this.funding_status) {
        this.slider = this.funding_status + "%";
        this.$refs.slider[0].setValue(this.slider);

        return false;
      }
      if (this.slider) {
        let data = {
          percent: this.slider.replace("%", ""),
        };

        this.$http
            .post(`/market/invoice/${this.invoice.id}/check`, data)
            .then((res) => {
              this.invoiceCheckResult = res.data.value;

              if (res.data) {
                this.items[5].des = res.data.value.investmentAmount
                    ? this.amountFormat(res.data.value.investmentAmount, this.invoice.currency.name)
                    : 0;

                this.items[6].des = res.data.value.hiveFree
                    ? this.amountFormat(res.data.value.hiveFree, this.invoice.currency.name)
                    : 0;

                this.items[7].des = res.data.value.expectedNetReturn
                    ? this.amountFormat(res.data.value.expectedNetReturn, this.invoice.currency.name)
                    : 0;

                localStorage.setItem(
                    "invoice_data",
                    JSON.stringify([
                      res.data.value.investmentAmount,
                      Number(this.current_balance),
                    ])
                );

                let total = Number(this.invoice.scorings[0].amount_with_discount); // - Number(this.invoice.discount);
                total =
                    "CHF " +
                    Number(total)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&'");
                this.$emit("setSlider", this.slider);
                this.$emit(
                    "modalValues",
                    res.data.value.investmentAmount,
                    res.data.value.hiveFree,
                    res.data.value.expectedNetReturn,
                    res.data.value.currency,
                );
              }
            });
      }
    },
    buyInvoice() {
      this.$emit(
          "goNext",
          this.slider,
          this.items[5].des,
          this.current_balance
      );
      this.$emit("disableBuy", true);
    },
    getInvoice() {
      this.$emit("disableBuy", true);
      let sum = 0;
      this.$http
          .post(`/market/invoice/${this.$route.params.id}/checkBalance`)
          .then((res) => {
            this.current_balance = res.data.value.balance;

            this.$http
                .post(`/market/invoice/${this.$route.params.id}`)
                .then((res) => {
                  this.invoice = res.data.value;
                  if (this.invoice) {

                    if (this.invoice.partial_purchase) {
                      this.sliderData = ['0%', '5%', '10%', '15%', '20%', '25%', '30%', '35%', '40%', '45%', '50%', '55%', '60%', '65%', '70%', '75%', '80%', '85%', '90%', '95%', '100%'];
                    } else {
                      this.sliderData = ['0%', '100%'];
                    }

                    if (this.invoice.fractions) {
                      if (this.invoice.fractions.length > 0) {
                        this.invoice.fractions.forEach((el) => {
                          this.funding_status += Number(el.fraction);
                          sum += Number(el.amount);
                        });
                      }

                      this.remainingAmount =
                          Number(this.invoice.scorings[0].amount_with_discount) - sum;

                      this.remainingAmount = this.remainingAmount.toFixed(2);
                      // for (let i = 0; i <= 100; i += 5) {
                      //   this.sliderData.push(i + "%");
                      // }

                      this.slider = this.funding_status + "%";
                    }

                    let total = Number(
                        this.invoice.scorings[0].amount_with_discount
                    ); // - Number(this.invoice.discount);
                    total =
                        "CHF " +
                        Number(total)
                            .toFixed(2)
                            .replace(/\d(?=(\d{3})+\.)/g, "$&'");

                    this.items = [
                      {
                        text: "available_funds",
                        des: this.current_balance
                            ? this.amountFormat(this.current_balance, this.invoice.currency.name)
                            : this.invoice.currency.name + "0",
                        icon: "HelpCircleIcon",
                        visible: 1,
                      },
                      {
                        text: "investment_amount",
                        des: total,
                        icon: "UserIcon",
                        visible: 0,
                      },
                      {
                        text: "funding_status",
                        des: this.funding_status,
                        icon: "HelpCircleIcon",
                        progress: true,
                        visible: 1,
                      },
                      {
                        text: "remaining_investment_amount",
                        des: this.amountFormat(this.remainingAmount, this.invoice.currency.name),
                        icon: "HelpCircleIcon",
                        visible: 1,
                      },

                      // {
                      //   text: "buy_full",
                      //   des: "",
                      //   icon: "HelpCircleIcon",
                      //   buyFull: true,
                      //   visible: 1,
                      // },
                      {
                        slider: true,
                        visible: 1,
                      },
                      {
                        text: "investment_amount",
                        des: "0",
                        icon: "HelpCircleIcon",
                        invest: true,
                        visible: 1,
                      },
                      {
                        text: "included_hive_fee",
                        des: "0",
                        icon: "HelpCircleIcon",
                        visible: 0,
                      },

                      {
                        text: "expected_net_return",
                        des: "0",
                        icon: "HelpCircleIcon",
                        visible: 1,
                      },
                    ];
                  }
                });
          });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
</style>
<style>
#slider-buttons .vue-slider-rail .vue-slider-dot,
#slider-buttons .vue-slider-rail .vue-slider-marks .vue-slider-mark {
  width: 20px !important;
  height: 20px !important;
}

#slider-buttons
.vue-slider-rail
.vue-slider-dot
.vue-slider-dot-tooltip
.vue-slider-dot-tooltip-inner {
  display: none !important;
}

@media (max-width: 530px) {
  #slider-buttons {
    padding: 0px !important;

    width: 100% !important;

  }

  #slider-buttons .vue-slider-rail .vue-slider-dot,
  #slider-buttons .vue-slider-rail .vue-slider-marks .vue-slider-mark {
    width: 2px !important;
    height: 2px !important;

  }

  #slider-buttons
  .vue-slider-rail
  .vue-slider-marks
  .vue-slider-mark
  .vue-slider-mark-label {
    display: none;
  }
}

@media (max-width: 370px) {
  #slider-buttons {
    padding: 0px !important;
    width: 100% !important;

  }

  #slider-buttons .vue-slider-rail .vue-slider-dot,
  #slider-buttons .vue-slider-rail .vue-slider-marks .vue-slider-mark {
    width: 1px !important;
    height: 1px !important;
  }


}

.font-1rem {
  font-size: 1rem;
}

.margin-top-2px {
  margin-top: 2px;
}
</style>
