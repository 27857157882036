import { render, staticRenderFns } from "./MyInvoices.vue?vue&type=template&id=34dc7fe7&scoped=true&"
import script from "./MyInvoices.vue?vue&type=script&lang=js&"
export * from "./MyInvoices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34dc7fe7",
  null
  
)

export default component.exports