<template>
  <div>
    <b-card>
        <Table :url="url" :fields="fields">

          <template #cell(total_amount)="data">
            {{ amountFormat(data.item.total_amount, data.item.invoice.currency.name) }}
          </template>

          <template #cell(progress)="data">
            <b-progress
              variant="success"
              :value="data.item.total_percentage"
              max="100"
              width="100px"
              height="14px"
            />
            <span> {{ data.item.total_percentage }}% </span>
          </template>
        </Table>
    </b-card>
  </div>
</template>

<script>
import Table from "@/components/common/Table2.vue";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';


export default {
  name: "MyInvoices",

  components: {
    Table,
  },
  data() {
    return {
      fields: [
        {
          key: "invoice_id",
          label: "Id",
        },
        {
          key: "total_amount",
          label: "amount",
        },
        {
          key: "progress",
          label: "funding_status",
        },
        // {
        //   key: "title",
        //   text: "title",
        //   label: "Document",
        //   sortable: true,
        //   value: "title",
        // },
        // { key: "ext", label: "Ext", sortable: true, value: "ext", text: "ext" },
        // {
        //   key: "company",
        //   label: "Company",
        //   sortable: true,
        //   value: "company",
        //   text: "company",
        // },
      ],
      title: "my_invoices",
      url: "/invoice/my_invoices",
    };
  },
  methods: {
    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },
  },
};
</script>


<style scoped>

</style>